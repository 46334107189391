


































































































































































































import {
    ConfirmModal,
    ESearch,
    FacetsFilters,
    Pagination,
    Scrollbar,
    SearchTerms,
    SvgImage,
    TailwindTableTabs,
    TwSelect,
} from '@/app/components/';
import { useFacetsFilters, useSearch } from '@/app/composable';
import store from '@/app/store';
import { S } from '@/app/utilities';
import { useAlerts } from '@/modules/alert/composable';
import { AlertSourceType } from '@/modules/alert/constants/alert.constants';
import { RefreshPageKeys } from '@/modules/notification/constants';
import { SearchAPI } from '@/modules/search/api';
import { WorkflowSortingOption } from '@/modules/workflow-designer/constants';
import { FireIcon, PlusIcon, RefreshIcon } from '@vue-hero-icons/outline';
import { FilterIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, onBeforeUnmount, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { WorkflowAPI } from '../api';
import { FakeWorkflowOverview, WorkflowOverview } from '../components';
import { useWorkflowFilters } from '../composable/workflow-filters';
import { ExecutionType } from '../constants';
import { Workflow } from '../types';

export default defineComponent({
    name: 'Workflows',
    metaInfo: {
        title: 'Analytics Pipelines',
    },
    components: {
        WorkflowOverview,
        FakeWorkflowOverview,
        OrbitSpinner,
        SvgImage,
        Pagination,
        Scrollbar,
        TwSelect,
        PlusIcon,
        FilterIcon,
        ESearch,
        TailwindTableTabs,
        SearchTerms,
        ConfirmModal,
        FacetsFilters,
        RefreshIcon,
        FireIcon,
    },
    setup(props, { root }) {
        const searchableFields = [
            {
                label: 'title',
                key: 'name.keyword',
                textColorClasses: 'text-primary-700',
                backgroundClasses: 'bg-primary-700',
                highlightClasses: 'bg-primary-300',
                widthClasses: 'pl-16 ml-2',
            },
            {
                label: 'description',
                key: 'description.keyword',
                textColorClasses: 'text-teal-700',
                backgroundClasses: 'bg-teal-700',
                highlightClasses: 'bg-teal-300',
                widthClasses: 'pl-28 ml-2',
            },
        ];
        const refresh = computed(() => {
            return store.state.notificationEngine.refresh[RefreshPageKeys.Workflow];
        });
        const queryParams = computed(() => JSON.stringify(root.$route.query));

        const pageSize = 10;
        const openFilters = ref<boolean>(true);
        const toBeDeleted = ref<string>('');
        const showDeleteModal = ref(false);
        const workflows = ref<any>([]);
        const rejectedItems = ref<number>(0);
        const totalResults = ref<number>(0);
        const user = ref(store.state.auth.user);

        const { checkAlert, fetchAlerts } = useAlerts(root, [AlertSourceType.Analytics]);
        fetchAlerts();

        const setSearchQuery = () => {
            searchQuery.value.filters =
                tab.value === 'mine'
                    ? Object.fromEntries(
                          Object.entries(reducedWorkflowFilters).filter(([key]) => !key.includes('user')),
                      )
                    : reducedWorkflowFilters;
        };

        const search = () => {
            setCommonSearchQueryValues();

            searchQuery.value.pipelineType = 'analytics';

            searchQuery.value.settings = {
                organisationId: tab.value === 'shared' && user.value.organisationId ? user.value.organisationId : null,
                userId: user.value.id,
            };

            const api = tab.value === 'mine' ? SearchAPI.myWorkflowsSearch : SearchAPI.sharedWorkflowsSearch;

            setQueryParams();

            exec(api(searchQuery.value)).then((res: any) => {
                workflows.value = res.data?.results;
                rejectedItems.value = res.data?.accessControl?.rejectedItems || 0;
                totalResults.value = res.data?.total;
            });
        };

        const {
            exec,
            loading,
            error,
            get,
            textNumberFilters,
            page,
            sortBy,
            tab,
            query,
            searchQuery,
            setTab,
            updateCurrentPage,
            filterChanged,
            sortByChanged,
            clearFilters,
            setCommonSearchQueryValues,
            setQueryParams,
            unsubscribeSearch,
            sortingFields,
        } = useSearch(root, 'workflows', pageSize, WorkflowSortingOption, 'mine', setSearchQuery, search);

        onBeforeUnmount(() => {
            unsubscribeSearch.value ? unsubscribeSearch.value() : undefined;
            if (refresh.value) store.dispatch.notificationEngine.disableRefreshAction(RefreshPageKeys.Workflow);
        });

        const { workflowFilters, reducedWorkflowFilters } = useWorkflowFilters(get);
        const { calculateSearchedFilters, defaultValue } = useFacetsFilters();

        const filtersInfo = computed(() => {
            switch (tab.value) {
                case 'mine':
                    return Object.fromEntries(
                        Object.entries(workflowFilters.value).filter(([key]) => !key.includes('user')),
                    );
                default:
                    return workflowFilters.value;
            }
        });

        const workflowTabs = computed(() => [
            { key: 'mine', label: 'My Pipelines' },
            { key: 'shared', label: 'Pipelines Shared with Me' },
        ]);

        const currentSearchedFilters = computed(() =>
            calculateSearchedFilters(searchQuery.value.filters, workflowFilters.value),
        );

        const confirmDelete = (id: string) => {
            toBeDeleted.value = id;
            showDeleteModal.value = true;
        };

        const openWorkflow = (workflow: Workflow) => {
            root.$router.push({
                name: 'workflow-designer:edit',
                params: { id: workflow.id, queryParams: queryParams.value },
            });
        };

        const configureWorkflow = (workflow: Workflow) => {
            root.$router.push({
                name: 'workflow-designer:edit',
                params: { id: workflow.id, queryParams: queryParams.value },
            });
        };

        const runWorkflow = async (workflow: Workflow) => {
            exec(WorkflowAPI.run(workflow.id, ExecutionType.Normal, null, workflow.imageVersion || 'latest', null))
                .then(() => {
                    (root as any).$toastr.s(
                        `Submitted request to run analytics pipeline <strong>${S.sanitizeHtml(
                            workflow.name,
                        )}</span></strong>`,
                        'Success',
                    );
                    search();
                })
                .catch((e: any) => {
                    (root as any).$toastr.e(
                        `Run of analytics pipeline has failed${
                            e.response?.data ? `: ${e.response?.data.message}` : '.'
                        }`,
                        'Error',
                    );
                });
        };

        const deleteWorkflow = () => {
            if (toBeDeleted.value) {
                // const idx = R.findIndex(R.propEq('id', toBeDeleted.value), jobs.value as any[]);
                const idx = R.findIndex(R.propEq('id', toBeDeleted.value), workflows.value);
                if (~idx) {
                    const workflow = workflows.value[idx];
                    showDeleteModal.value = false;

                    exec(WorkflowAPI.delete(workflow.id))
                        .then(() => {
                            (root as any).$toastr.s(
                                `Analytics pipeline '${S.sanitizeHtml(workflow.name)}' has been deleted`,
                                'Success',
                            );
                            page.value = 1;
                            updateCurrentPage();
                        })
                        .catch((e: any) => {
                            if (e.response.data.message === 'Locked') {
                                (root as any).$toastr.e(
                                    `The analytics pipeline '${S.sanitizeHtml(
                                        workflow.name,
                                    )}' failed to be deleted because it is locked by another user.`,
                                    'Error',
                                );
                            } else {
                                (root as any).$toastr.e(
                                    `The analytics pipeline '${S.sanitizeHtml(workflow.name)}' failed to be deleted`,
                                    'Error',
                                );
                            }
                        })
                        .finally(() => {
                            store.commit.notificationEngine.SET_NOTIFICATIONS_AFTER_REFERENCED_ITEM_DELETE({
                                referenceId: workflow.id,
                                referenceType: 'Workflow',
                            });
                        });
                }
            }
        };

        const historyWorkflow = (workflow: Workflow) => {
            root.$router.push({ name: 'history', params: { workflowId: workflow.id, queryParams: queryParams.value } });
        };

        const editWorkflow = (workflow: Workflow) =>
            root.$router.push({
                name: 'workflow',
                params: { id: workflow.id, mode: 'edit', queryParams: queryParams.value },
            });

        const cloneWorkflow = (workflow: Workflow) =>
            root.$router.push({
                name: 'workflow',
                params: { id: workflow.id, mode: 'clone', queryParams: queryParams.value },
            });

        const viewWorkflow = (workflow: Workflow) =>
            root.$router.push({
                name: 'workflow',
                params: { id: workflow.id, mode: 'view', queryParams: queryParams.value },
            });

        const refreshPage = () => {
            search();
            store.dispatch.notificationEngine.disableRefreshAction(RefreshPageKeys.Workflow);
        };

        textNumberFilters.value = { pipeline: defaultValue(get, 'pipeline', false) };
        setSearchQuery();
        search();

        watch(showDeleteModal, (value: boolean) => {
            if (!value) toBeDeleted.value = '';
        });

        return {
            loading,
            error,
            pageSize,
            openWorkflow,
            configureWorkflow,
            deleteWorkflow,
            historyWorkflow,
            editWorkflow,
            cloneWorkflow,
            viewWorkflow,
            openFilters,
            tab,
            workflowTabs,
            setTab,
            currentSearchedFilters,
            sortingFields,
            sortByChanged,
            filterChanged,
            clearFilters,
            searchQuery,
            updateCurrentPage,
            query,
            sortBy,
            confirmDelete,
            showDeleteModal,
            workflows,
            totalResults,
            rejectedItems,
            page,
            filtersInfo,
            runWorkflow,
            queryParams,
            textNumberFilters,
            refresh,
            refreshPage,
            checkAlert,
            searchableFields,
        };
    },
});
