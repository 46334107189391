































































































































































































































































































































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { ExecutionStorageLocation, ExecutionType, WorkflowDesignerViews } from '../constants';
import { CalendarIcon } from '@vue-hero-icons/outline';
import { ExclamationIcon } from '@vue-hero-icons/solid';

import { LockWidget, ValidationWidget, RunWidget, UpgradeWidget } from './menu';
import { useWorkflowTriggers } from '../composable';
import { Workflow } from '../types';
export default defineComponent({
    name: 'WorkflowMenu',
    model: { prop: 'activePage', event: 'update' },
    props: {
        activePage: { type: String as PropType<WorkflowDesignerViews>, required: true },
        activeDropdown: {
            type: String,
            default: null,
        },
        disableClickEvent: {
            type: Boolean,
            default: false,
        },
        runningExecution: {
            type: Object,
            default: null,
        },
        validationErrors: {
            type: Array,
            default: () => [],
        },
        isFinalised: {
            type: Boolean,
            default: false,
        },
        isDeprecated: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        canBeReopened: {
            type: Boolean,
            default: false,
        },
        isOnPremise: {
            type: Boolean,
            default: false,
        },
        isLocked: {
            type: Boolean,
            default: false,
        },
        canUpgrade: {
            type: Boolean,
            default: false,
        },
        workflow: {
            type: Object as PropType<Workflow>,
            required: true,
        },
    },
    components: { ValidationWidget, LockWidget, RunWidget, UpgradeWidget, CalendarIcon, ExclamationIcon },
    setup(props, { emit }) {
        const workflow = computed(() => props.workflow);
        const { hasAccessToTriggers, hasInvalidInputAssetInTriggers } = useWorkflowTriggers(workflow, false);

        const openPage = (page: string) => {
            if (!props.disableClickEvent) {
                emit('update', page);
            }
        };

        const toggleDropdown = (dropdown: string) => {
            if (!props.disableClickEvent) {
                emit('toggle-dropdown', dropdown);
            }
        };

        const runWorkflow = () => {
            emit('run');
        };

        const finaliseWorkflow = () => {
            if (!R.isNil(props.validationErrors) && props.validationErrors.length === 0) {
                emit('finalise');
            }
        };

        const isLocalStorage = computed(
            () => workflow.value.configuration?.location === ExecutionStorageLocation.Local,
        );

        return {
            emit,
            uuidv4,
            openPage,
            toggleDropdown,
            runWorkflow,
            ExecutionType,
            finaliseWorkflow,
            WorkflowDesignerViews,
            hasAccessToTriggers,
            hasInvalidInputAssetInTriggers,
            isLocalStorage,
        };
    },
});
