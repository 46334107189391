


























































































































































































































































































































































































































































































































































import {
    ConfirmModal,
    CopyToClipboardButton,
    FileWithIcon,
    FormBlock,
    JsonParser,
    SvgImage,
    TwAccordion,
    TwAccordionCard,
    TwButton,
} from '@/app/components';
import { useAxios, useSchedule } from '@/app/composable';
import store from '@/app/store';
import { requiredValidator } from '@/app/validators';
import { RefreshIcon } from '@vue-hero-icons/outline';
import { InformationCircleIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { extend, ValidationObserver } from 'vee-validate';
import { JobsAPI } from '../../api';
import { AdditionalResponseData, ResponseHandling, SampleUpload } from '../../components';
import { useHarvester } from '../../composable';
import { RetrievalType } from '../../constants';
import Schedules from './external-api/Schedules.vue';

dayjs.extend(utc);

extend('required', requiredValidator);

export default defineComponent({
    name: 'LargeFilesConfiguration',
    model: {
        prop: 'configuration',
    },
    components: {
        FormBlock,
        TwButton,
        ValidationObserver,
        JsonParser,
        SvgImage,
        ResponseHandling,
        SampleUpload,
        Schedules,
        CopyToClipboardButton,
        TwAccordion,
        TwAccordionCard,
        RefreshIcon,
        ConfirmModal,
        OrbitSpinner,
        AdditionalResponseData,
        FileWithIcon,
        InformationCircleIcon,
    },
    props: {
        jobId: {
            type: Number,
            required: true,
        },
        jobConfig: {
            type: Object,
        },
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: Array,
            required: false,
        },
        processedSample: {
            type: Array,
            required: false,
        },
        parsedSample: {
            type: [Object, Array],
        },
        files: {
            type: Object,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        basePath: {
            type: String,
            required: true,
        },
        isOnUpdate: {
            type: Boolean,
            default: false,
        },
        skipSampleRun: {
            type: Boolean,
            default: false,
        },
        workflowId: {
            type: String,
            required: true,
        },
        stepId: {
            type: Number,
            required: true,
        },
        jobCreatedById: {
            type: Number,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        pipelineFinalized: {
            type: Boolean,
            required: true,
        },
        hasAnonymisation: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, { emit, root }) {
        const { exec } = useAxios(true);

        const { validSchedules, schedulesError } = useSchedule();

        const filesValidationRef = ref<any>(null);
        const sampleRef = ref<any>(null);
        const fileRef = ref<any>(null);
        const inputFilename = ref<any>(null);
        const necessaryFields = ref<any>(null);
        const extraInvalidFields = ref<any>(null);
        const loading = ref<any>(false);
        const showValidationBar = ref<boolean>(false);
        const separator = '||';
        const finalSample = ref<any>(props.sample);
        const user = computed(() => store.state.auth.user);
        const schedules: any = ref([]);
        const initializationFailed = ref<boolean>(false);
        const resettingSecretKey = ref<boolean>(false);
        const showResetSecretKeyModal = ref<boolean>(false);
        const initializing = ref<boolean>(false);
        const processedFiles = ref<any[]>([]);
        const newRetrievalType = ref<string | undefined>(undefined);
        const showChangeRetrievalTypeModal = ref(false);

        const retrievalTypes = computed(() =>
            props.hasAnonymisation
                ? {
                      immediately: 'Retrieve Immediately',
                      once: 'Retrieve Once',
                  }
                : {
                      immediately: 'Retrieve Immediately',
                      once: 'Retrieve Once',
                      periodic: 'Periodic Retrieval (according to schedule)',
                  },
        );

        const connectionDetails = reactive({
            url: null,
            accessKey: null,
            secretKey: null,
        });

        const { changeFinalSample } = useHarvester(root, emit);

        const tomorrowDate = computed(() => {
            const date = new Date();
            date.setUTCHours(0, 0, 0, 0);
            return date.setDate(date.getDate() + 1);
        });

        const errorAlert: any = ref({
            title: null,
            body: {
                necessary: null,
                invalid: null,
            },
        });

        const disableFileTypeChange = computed(
            () =>
                props.completed ||
                props.isOnUpdate ||
                (props.configuration.files && props.configuration.files.length > 0),
        );

        const imageBasedOnFiletype = computed(() =>
            props.configuration.fileType === 'other' ? '/img/files_uploaded.svg' : '/img/no_data.svg',
        );

        const messageBasedOnFiletype = computed(() =>
            props.configuration.fileType === 'other' ? 'Uploaded successfully' : 'No data sample uploaded',
        );

        const clearErrorAlert = () => {
            errorAlert.value = {
                title: null,
                body: {
                    necessary: null,
                    invalid: null,
                },
            };
        };

        const retrieveOnce = computed(() => props.configuration.retrieval.type === RetrievalType.Once);

        const validateAndProceed = async () => {
            if (!filesValidationRef.value) return;

            if (
                (!props.completed || props.isOnUpdate) &&
                props.configuration.retrieval.type === 'periodic' &&
                schedules.value.length === 0
            ) {
                (root as any).$toastr.e('At least one schedule must be defined.', 'Failed');
                return;
            }

            const valid = await filesValidationRef.value.validate();
            if (!valid) return;

            if (!props.pipelineFinalized) {
                const schedulesAreValid = validSchedules(schedules.value, retrieveOnce.value);
                if (!schedulesAreValid) {
                    (root as any).$toastr.e(schedulesError.description, schedulesError.title);
                    return;
                }
            }

            if (props.configuration.isSampleCropped && errorAlert.value.title) {
                clearErrorAlert();
            }
            if (!props.processedSample && props.configuration.fileType === 'parquet') {
                emit('execute-sample-run', true);
            } else {
                emit('next-tab');
            }
        };

        const validate = async () => {
            return filesValidationRef.value.validate();
        };

        const confirmResetSecretKey = () => {
            showResetSecretKeyModal.value = true;
        };

        const resetSecretKey = async () => {
            showResetSecretKeyModal.value = false;
            resettingSecretKey.value = true;
            exec(JobsAPI.resetS3SecretKey(props.stepId))
                .then((res: any) => {
                    connectionDetails.secretKey = res.data.secretKey;
                    resettingSecretKey.value = false;
                    (root as any).$toastr.s('Secret key has been reset!', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to reset secret key', 'Failed');
                    resettingSecretKey.value = false;
                });
        };

        const filesAdded = (data: File[]) => {
            emit('files-changed', { data });
        };

        const changeConfig = (value: any) => {
            emit('job-config-change', value);
        };

        const setLoading = (loadingValue: boolean) => {
            loading.value = loadingValue;
            emit('set-loading', loadingValue);
        };

        const setErrorAlert = (value: string | null) => {
            errorAlert.value.title = value;
        };

        const modifyFinalSample = (sample: any) => {
            finalSample.value = sample;
            changeFinalSample(sample, props.configuration.source);
        };

        const updateSchedules = (updatedSchedules: any) => {
            schedules.value = updatedSchedules;
        };

        const resetButton = computed(() => {
            if (resettingSecretKey.value) return 'Resetting Secret Key...';
            return 'Reset Secret Key';
        });

        const originalSample = computed(() => {
            if (!props.sample) return [];
            const clonedSample = R.clone(props.sample);
            for (let i = 0; i < clonedSample.length; i++) {
                const row: any = clonedSample[i];
                const newRow = {};
                for (let j = 0; j < props.configuration.params.fields.length; j++) {
                    const field = props.configuration.params.fields[j];
                    newRow[field] = row[field];
                }
                clonedSample[i] = newRow;
            }
            return clonedSample;
        });

        const changeAdditionalParameters = () => {
            const clonedSample = R.clone(originalSample.value);
            for (let i = 0; i < clonedSample.length; i++) {
                const row: any = clonedSample[i];
                for (let j = 0; j < props.configuration.response.additional.length; j++) {
                    const additional = props.configuration.response.additional[j];
                    row[additional.key] = additional.displayValue;
                }
            }
            emit('sample-uploaded', clonedSample);
        };

        const getProcessedFiles = () => {
            exec(JobsAPI.getStepProcessedFiles(props.stepId))
                .then((res: any) => {
                    processedFiles.value = res.data;
                })
                .catch(() => {
                    //do nothing
                });
        };

        const checkRetrievalTypeChange = (event: any) => {
            if (event.target.value !== props.configuration?.retrieval?.type && schedules.value.length) {
                newRetrievalType.value = event.target.value;
                showChangeRetrievalTypeModal.value = true;
            } else emit('update-retrieval-type', event.target.value);
        };

        const confirmRetrievalTypeChange = () => {
            emit('update-retrieval-type', newRetrievalType.value);
            showChangeRetrievalTypeModal.value = false;
            newRetrievalType.value = undefined;
        };

        const invalidNoOfSchedules = computed(
            () => props.configuration.retrieval.type !== 'immediately' && !(schedules.value && schedules.value.length),
        );

        getProcessedFiles();

        const processedFilesList = computed(() => [
            {
                title: 'Processed Successfully',
                files: processedFiles.value.filter((file: any) => file.status === 'PROCESSED'),
                tooltip: 'Files that have been processed successfully by the last execution (latest 6).',
            },
            {
                title: 'Failed to be processed',
                files: processedFiles.value.filter((file: any) => file.status === 'FAILED'),
                tooltip: 'Files that failed to be processed by the latest execution.',
            },
            {
                title: 'Skipped',
                files: processedFiles.value.filter((file: any) => file.status === 'SKIPPED'),
                tooltip:
                    'Files that haved been skipped from the latest execution because they have the wrong format or are below the size threshold.',
            },
        ]);

        watch(
            () => props.configuration.connectionDetails,
            (details) => {
                if (details.url) {
                    connectionDetails.url = details.url;
                    connectionDetails.accessKey = details.accessKey;
                    connectionDetails.secretKey = details.secretKey;
                    if (initializing.value) {
                        props.configuration.connectionDetails.secretKey = null; // eslint-disable-line no-param-reassign
                        emit('save-step');
                        initializing.value = false;
                    }
                } else {
                    if (!initializing.value) {
                        initializing.value = true;
                        emit('initialize-step');
                    } else {
                        initializing.value = false;
                        initializationFailed.value = true;
                    }
                }
            },
            { immediate: true },
        );

        return {
            user,
            tomorrowDate,
            showResetSecretKeyModal,
            connectionDetails,
            disableFileTypeChange,
            fileRef,
            filesAdded,
            filesValidationRef,
            sampleRef,
            validate,
            validateAndProceed,
            errorAlert,
            necessaryFields,
            extraInvalidFields,
            imageBasedOnFiletype,
            messageBasedOnFiletype,
            loading,
            inputFilename,
            showValidationBar,
            separator,
            finalSample,
            resetButton,
            initializationFailed,
            resettingSecretKey,
            initializing,
            originalSample,
            processedFiles,
            changeConfig,
            modifyFinalSample,
            setErrorAlert,
            clearErrorAlert,
            setLoading,
            updateSchedules,
            confirmResetSecretKey,
            resetSecretKey,
            changeAdditionalParameters,
            dayjs,
            processedFilesList,
            invalidNoOfSchedules,
            retrievalTypes,
            RetrievalType,
            checkRetrievalTypeChange,
            confirmRetrievalTypeChange,
            showChangeRetrievalTypeModal,
        };
    },
});
