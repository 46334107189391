import { UserRoles } from '@/app/constants';
import { AuthGuard } from '@/modules/auth/router/auth.guard';
import { AuthenticateUser } from '@/modules/auth/router/authenticate-user';
import { RoleGuard } from '@/modules/auth/router/role.guard';
import { NavigationGuard, Route } from 'vue-router';
import multiguard from 'vue-router-multiguard';

type Next = Parameters<NavigationGuard>[2];

export default [
    {
        name: 'user:edit',
        path: '/user',
        component: () => import(/* webpackChunkName: "user" */ '@/modules/user/views/EditUser.vue'),
        meta: { title: 'User' },
        beforeEnter: AuthGuard,
    },

    {
        name: 'admin-portal',
        path: '/admin',
        component: () => import(/* webpackChunkName: "user" */ '@/modules/user/views/AdminPortal.vue'),
        meta: { title: 'Admin Portal' },
        beforeEnter: multiguard([
            AuthGuard,
            (to: Route, from: Route, next: Next) => RoleGuard(to, from, next, [UserRoles.Admin]),
        ]),
    },
    {
        name: 'docs',
        path: '/docs',
        meta: { title: 'Docs', feature: 'docs' },
        beforeEnter: async () => {
            window.open('https://docs.synergies-eds.eu/', '_blank');
        },
    },
];
