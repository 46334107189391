




















































































































































































import { MonitoringAPI } from '@/app/api';
import { TailwindTable, WeatherDynamicIcon } from '@/app/components';
import { ColumnType } from '@/app/constants';
import { MonitoringExecutionQuery } from '@/app/types';
import { useAxios } from '@/app/composable';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';

export default defineComponent({
    name: 'DcjCompleteness',
    props: {
        query: { type: Object as PropType<MonitoringExecutionQuery>, required: true },
    },
    components: { TailwindTable, WeatherDynamicIcon },
    setup(props, { root, emit }) {
        const { exec, loading } = useAxios(true);

        const completeness: Ref<any> = ref<any>();

        const fetch = async (query: MonitoringExecutionQuery) => {
            // omit status facet to include successful exections in aggregation
            await exec(MonitoringAPI.executionCompleteness({ ...query, facets: { ...query.facets, status: [] } }))
                .then((res: any) => {
                    completeness.value = res.data;
                    emit('update-show-results-warning', res.data.pagination.total >= 1000);
                })
                .catch((e) => {
                    (root as any).$toastr.e(e.response.data.message, 'Error');
                    throw e;
                });
        };

        const columns = computed(() => [
            {
                key: 'jobId',
                label: 'ID',
                type: ColumnType.Integer,
                showAfter: 'sm',
                link: (value: number) => {
                    return value ? { name: 'dcj:history', params: { id: `${value}` } } : null;
                },
            },
            {
                key: 'workflowId',
                label: 'Pipeline ID',
                type: ColumnType.Uid,
                showAfter: 'md',
                copy: true,
            },
            {
                key: 'asset',
                label: 'Asset',
                type: ColumnType.Object,
                link: (value: { id: number }) => {
                    return value ? { name: 'assets:view', params: { id: `${value.id}` } } : null;
                },
            },
            {
                key: 'recordsStored',
                label: 'Stored',
                type: ColumnType.Integer,
                showAfter: 'sm',
            },
            {
                key: 'recordsDropped',
                label: 'Dropped',
                type: ColumnType.Integer,
                showAfter: 'md',
            },
            {
                key: 'recordsTransformed',
                label: 'Transformed',
                type: ColumnType.Integer,
                showAfter: 'md',
            },
            {
                key: 'completeness',
                label: 'Completeness (%)',
                type: ColumnType.Double,
                sortable: true,
            },
            {
                key: 'reliability',
                label: 'Reliability',
                type: ColumnType.Double,
            },
        ]);

        const rows = computed(() => completeness.value?.results);
        const rejectedItems = computed(
            () => (completeness.value?.accessControl?.rejectedItems ?? 0) + (completeness.value?.deletedPipelines ?? 0),
        );
        const pagination = computed(() => completeness.value?.pagination);

        const changeSort = (sortBy: { field: string; asc: boolean }) => {
            emit('sort', sortBy);
        };

        watch(
            () => props.query,
            (query: MonitoringExecutionQuery, oldQuery: MonitoringExecutionQuery | undefined) => {
                if (!oldQuery || !R.equals(oldQuery, query)) fetch(query);
            },
            { deep: true, immediate: true },
        );

        watch(
            () => loading.value,
            (l: boolean) => emit('loading', l),
            { immediate: true },
        );

        return {
            Math,
            rows,
            pagination,
            loading,
            columns,
            changeSort,
            rejectedItems,
        };
    },
});
