




















































































import { MonitoringAPI } from '@/app/api';
import { TailwindTable, TimelinessBadge } from '@/app/components';
import { ColumnPosition, ColumnType } from '@/app/constants';
import { MonitoringExecutionQuery } from '@/app/types';
import { useAxios } from '@/app/composable';
import { ExclamationIcon } from '@vue-hero-icons/solid';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';

export default defineComponent({
    name: 'DcjTimeliness',
    props: {
        query: { type: Object as PropType<MonitoringExecutionQuery>, required: true },
    },
    components: { TailwindTable, TimelinessBadge, ExclamationIcon },
    setup(props, { root, emit }) {
        const { exec, loading } = useAxios(true);

        const timeliness: Ref<any> = ref<any>();

        const fetch = async (query: MonitoringExecutionQuery) => {
            // omit status facet to include successful exections in aggregation
            await exec(MonitoringAPI.executionTimeliness({ ...query, facets: { ...query.facets, status: [] } }))
                .then((res: any) => {
                    timeliness.value = res.data;
                    emit('update-show-results-warning', res.data.pagination.total >= 1000);
                })
                .catch((e) => {
                    (root as any).$toastr.e(e.response.data.message, 'Error');
                    throw e;
                });
        };

        const columns = computed(() => [
            {
                key: 'jobId',
                label: 'ID',
                type: ColumnType.Integer,
                showAfter: 'sm',
                link: (value: number) => {
                    return value ? { name: 'dcj:history', params: { id: `${value}` } } : null;
                },
            },
            {
                key: 'workflowId',
                label: 'Pipeline ID',
                type: ColumnType.Uid,
                showAfter: 'md',
                copy: true,
            },
            {
                key: 'asset',
                label: 'Asset',
                type: ColumnType.Object,
                link: (value: { id: number }) => {
                    return value ? { name: 'assets:view', params: { id: `${value.id}` } } : null;
                },
            },
            {
                key: 'timeliness',
                label: 'Timeliness',
                type: ColumnType.String,
                position: ColumnPosition.Center,
                sortable: true,
            },
            {
                key: 'lastUpdated',
                label: 'Last Updated',
                type: ColumnType.Datetime,
                formatInUtc: true,
            },
        ]);

        const rows = computed(() => timeliness.value?.results);
        const rejectedItems = computed(
            () => (timeliness.value?.accessControl?.rejectedItems ?? 0) + (timeliness.value?.deletedPipelines ?? 0),
        );
        const pagination = computed(() => timeliness.value?.pagination);

        const changeSort = (sortBy: { field: string; asc: boolean }) => {
            emit('sort', sortBy);
        };

        watch(
            () => props.query,
            (query: MonitoringExecutionQuery, oldQuery: MonitoringExecutionQuery | undefined) => {
                if (!oldQuery || !R.equals(oldQuery, query)) fetch(query);
            },
            { deep: true, immediate: true },
        );

        watch(
            () => loading.value,
            (l: boolean) => emit('loading', l),
            { immediate: true },
        );

        return {
            rows,
            pagination,
            loading,
            columns,
            changeSort,
            rejectedItems,
            timeliness,
        };
    },
});
